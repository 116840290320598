declare const window: any;
declare const Swiper: any;
declare const $: any;
declare const gsap: any;
declare const ScrollTrigger: any;
declare const THREE: any;

export const index = function () {
    let elem = document.getElementById('indexPage');
    if (elem){
        initSwIntro();

        initSwMember();
        initSwPartners();

        scrollEffectAbout();
    }
};

const optSwLogo = {
    slidesPerView: 3,
    spaceBetween: 20,
    
    autoplay: {
        delay: 1600,
        disableOnInteraction: false,
    },
}

function initSwMember(){
    const sw = new Swiper('.index-logo-members .swiper', optSwLogo);
    return sw;
}
function initSwPartners(){
    const sw = new Swiper('.index-logo-partners .swiper', optSwLogo);
    return sw;
}

const optSwIntro = {
    slidesPerView: 1,
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },

    speed: 2000,

    autoplay: {
        delay: 1600,
        disableOnInteraction: false,
    },
}
function initSwIntro(){
    const sw = new Swiper('.index-intro__thumb .swiper', optSwIntro);
}

function scrollEffectAbout(){
    const idxTitle = document.querySelector('.index-about-wrapper .title');

    let h1Title = idxTitle.querySelector('h1');
    let h2Title = idxTitle.querySelector('h2');

    h1Title.style.transform = "translateX(-17%)";
    h2Title.style.transform = "translateX(10%)";
    
    let hScreen = window.screen.height || window.innerHeight;
    let hIdxWrapper = idxTitle.clientHeight / 2;

    window.addEventListener('scroll', function(e:any){
        const spaceTop = idxTitle.getBoundingClientRect().y - hScreen + hIdxWrapper;

        if (spaceTop <= 0){
            h1Title.style.transform = "translateX(0)";
            h2Title.style.transform = "translateX(0)";
        }
    })
}