declare const window: any;
declare const Swiper: any;
declare const $: any;
declare const gsap: any;
declare const ScrollTrigger: any;
declare const THREE: any;

export const banner = function (){
    initSwBanner();
}

const optSwBanner = {
    slidesPerView: 1,
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },

    speed: 2000,

    autoplay: {
        delay: 1600,
        disableOnInteraction: false,
    },
}
function initSwBanner(){
    if (document.querySelector('.banner-section__thumb .swiper')){
        const sw = new Swiper('.banner-section__thumb .swiper', optSwBanner);
    }
}