declare const window :any;

export const popup = function(){
    init();
}

function init(){
    let btnPopups = document.querySelectorAll('a[data-popup]');
    btnPopups.forEach(function(item){
        window.popupHandle(item, function(){
            return true;
        })
    })
}


// // const input = <HTMLInputElement>document.querySelector("input[file]");
// // const fileTypes = [
// //     "image/apng",
// //     "image/bmp",
// //     "image/gif",
// //     "image/jpeg",
// //     "image/pjpeg",
// //     "image/png",
// //     "image/svg+xml",
// //     "image/tiff",
// //     "image/webp",
// //     "image/x-icon"
// // ];

// let timeoutShow: any;

// function closeHandle(){
//     let popups = document.querySelectorAll('.popup');
//     popups.forEach(function(popup:HTMLElement){
// 		if (popup){
// 			let close =<HTMLElement> popup.querySelector('.close');
// 			close.onclick = function(e){
// 				popup.classList.remove('show');
// 				popup.style.display = "none";
// 			}
			
// 			popup.addEventListener('click', function(e){
// 				e.stopPropagation();
// 				let elePopup = <HTMLElement>e.target;
		
// 				if (elePopup.classList.contains('popup')){
// 					popup.classList.remove('show');
// 					popup.style.display = "none";
// 				}
// 			})


// 			let btnCancel = popup.querySelector('.cancel');
// 			if (btnCancel){
// 				btnCancel.addEventListener('click', function(e){
// 					e.preventDefault();
// 					popup.classList.remove('show');
// 					popup.style.display = "none";
// 				})
// 			}
// 		}
		
// 	})

// }

// function showHandleChangePw(){
//     let popupLinks = document.querySelectorAll('a[href="#popup-changepw"]');
//     popupLinks.forEach(function(item:HTMLElement){
//         item.onclick = function(e){
//             e.preventDefault();
//             let popup = <HTMLElement>document.querySelector('.popup-changepw');
//             popup.classList.add('show');

// 			popup.style.display = "";
//         }
//     })

//     let popupData = document.querySelectorAll('[data-popup="popup-changepw"]');
//     popupData.forEach(function(item:HTMLElement){
//         item.onclick = function(e){
//             e.preventDefault();
//             let popup = <HTMLElement>document.querySelector('.popup-changepw');
//             popup.classList.add('show');
// 			popup.style.display = "";
//         }
//     })

// 	// let popup = document.querySelector('.popup-notify');
// 	// let text = popup.querySelector('.wrapper h2');
// 	// text.innerHTML = "Cảm ơn đã đăng kí ở đây!";
// 	// popup.classList.add('show');
// }
// function showHandleDel(){
//     let popupLinks = document.querySelectorAll('a[href="#popup-del"]');
//     popupLinks.forEach(function(item:HTMLElement){
//         item.onclick = function(e){
//             e.preventDefault();
//             let popup = <HTMLElement>document.querySelector('.popup-del');
//             popup.classList.add('show');

// 			popup.style.display = "";
//         }
//     })

//     let popupData = document.querySelectorAll('[data-popup="popup-del"]');
//     popupData.forEach(function(item:HTMLElement){
//         item.onclick = function(e){
//             e.preventDefault();
//             let popup = <HTMLElement>document.querySelector('.popup-del');
//             popup.classList.add('show');
// 			popup.style.display = "";
//         }
//     })
// }


// export const popup = function () {
// 	showHandleChangePw();
// 	showHandleDel();
// 	closeHandle();

// 	// const sizeMb = 3;
// 	// const totalBytes_max = sizeMb * 1048576;

// 	// const inputItemFiles = document.querySelectorAll(".inputFile");
// 	// inputItemFiles.forEach(function (inputItemFile) {
// 	// 	const input = <HTMLInputElement>(
// 	// 		inputItemFile.querySelector(".file-upload")
// 	// 	);
// 	// 	input.addEventListener("change", updateImageDisplay);

// 	// 	const preview = <HTMLElement>inputItemFile.querySelector(".preview");
//     //     preview.style.marginTop = "0";

// 	// 	const answerSuccessFirst = inputItemFile.querySelector(
// 	// 		".spanList .success",
// 	// 	).children[0].textContent;
// 	// 	const answerSuccessLast = inputItemFile.querySelector(
// 	// 		".spanList .success",
// 	// 	).children[1].textContent;
// 	// 	const answerErrorSize = inputItemFile.querySelector(
// 	// 		".spanList .errorSize",
// 	// 	).children[0].textContent;
// 	// 	const answerErrorFile = inputItemFile.querySelector(
// 	// 		".spanList .errorFile",
// 	// 	).children[0].textContent;

// 	// 	const fileTypes = input.getAttribute("accept").split(",");

// 	// 	function checkTotalSizeFiles(curFiles: any) {
// 	// 		if (curFiles.length > 0) {
// 	// 			let sizeFile = 0;
// 	// 			for (const file of curFiles) {
// 	// 				sizeFile += file.size;
// 	// 			}

// 	// 			if (sizeFile > totalBytes_max) {
// 	// 				return false;
// 	// 			}
// 	// 		}
// 	// 		return true;
// 	// 	}

// 	// 	function validFileType(file: any) {
// 	// 		// if (file.type.includes("image/") && fileTypes.includes("image/*")) {
// 	// 		// 	return true;
// 	// 		// }
// 	// 		return fileTypes.includes(file.type);
// 	// 	}

// 	// 	function returnFileSize(number: any) {
// 	// 		if (number < 1024) {
// 	// 			return number + "bytes";
// 	// 		} else if (number >= 1024 && number < 1048576) {
// 	// 			return (number / 1024).toFixed(1) + "KB";
// 	// 		} else if (number >= 1048576) {
// 	// 			return (number / 1048576).toFixed(1) + "MB";
// 	// 		}
// 	// 	}

// 	// 	function updateImageDisplay() {
//     //         console.log(input.files);

// 	// 		while (preview.firstChild) {
// 	// 			preview.removeChild(preview.firstChild);
// 	// 		}

// 	// 		const curFiles = input.files;
// 	// 		if (checkTotalSizeFiles(curFiles) === false) {
// 	// 			const para = document.createElement("p");
// 	// 			para.textContent = `${answerErrorSize} ${sizeMb}MB`;
// 	// 			preview.appendChild(para);
//     //             preview.style.marginTop = "";
// 	// 			return;
// 	// 		}
            
// 	// 		if (curFiles.length > 0) {
//     //             preview.style.marginTop = "";

// 	// 			const list = document.createElement("ol");
// 	// 			preview.appendChild(list);

// 	// 			for (const file of curFiles) {
// 	// 				const listItem = document.createElement("li");
// 	// 				const para = document.createElement("p");

// 	// 				if (validFileType(file)) {
// 	// 					para.textContent = `${answerSuccessFirst} "${
// 	// 						file.name
// 	// 					}", ${answerSuccessLast} ${returnFileSize(file.size)}`;
// 	// 					listItem.appendChild(para);

// 	// 					// const image = document.createElement("img");
// 	// 					// image.src = URL.createObjectURL(file);
// 	// 					// listItem.appendChild(image);
// 	// 				} else {
// 	// 					para.textContent = `${answerErrorFile}`;
// 	// 					listItem.appendChild(para);
// 	// 				}

// 	// 				list.appendChild(listItem);
// 	// 			}
// 	// 		}
//     //         else{
//     //             preview.style.marginTop = "0";
//     //         }
// 	// 	}
// 	// });


// };
