// https://fancyapps.com/docs/ui/fancybox
// https://www.npmjs.com/package/axios
// https://greensock.com/
// https://swiperjs.com/get-started
declare const window:any;

declare const Swiper : any;
declare const $ : any;
declare const AOS : any;
declare const gsap : any;
declare const Fancybox:any;
declare const Panzoom:any;
declare const THREE: any;

// const load = document.createElement('div');
// load.classList.add('loading');
// load.innerHTML = `<span class="loader"></span>
//   <span class="iconLogo">
//   <svg width="22" height="33" viewBox="0 0 22 33" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M21.5805 19.2695H14.4287V32.9993H14.48L21.6245 25.8276V19.3136L21.5805 19.2695Z" fill="#8BC53F"/>
//     <path d="M0.355469 5.43731H7.56586V-0.00390625H0.355469V5.43731ZM0.355469 25.8612L7.46338 32.9998H7.56586V7.1641H0.355469V25.8612Z" fill="#8BC53F"/>
//     <path d="M14.4291 25.7773H7.55176V33.0005H14.4291V25.7773Z" fill="#0D004C"/>
//     <path d="M14.3849 12.0469H9.37793V19.27H21.5807L14.3849 12.0469Z" fill="#0D004C"/>
//   </svg>
//   </span>`;
// document.body.style.overflow = "hidden";
// document.body.append(load);


// declare const ScrollTrigger: any;
// gsap.registerPlugin(ScrollTrigger);

// Fancybox.defaults.ScrollLock = false;

// AOS.init({
//   once: true
// });

// import axios from "axios";

//////////////////////////////////////////////////////////
import {_base} from "./libraries/_base";
import {_imgClass} from "./libraries/_imgClass";
import {initPage} from "./libraries/pagination";

import {fancyboxLib} from "./libraries/fancybox-lib";
// import {gsapInitScroll} from './libraries/gsapInitScroll';
// import {_dateTime} from './libraries/_dateTimePicker';
// import {initForm} from "./libraries/_initForm";
import api from "./libraries/fetchData";
import {settings} from "./components/fixed-settings";



_base();
initPage();
// gsapInitScroll();

function copyMaker(){
  let copyElements = document.querySelectorAll('.copyMaker');
  copyElements.forEach(function(copyElement){
    if (!copyElement.classList.contains('i-cm-completed')){
      window.copyMaker(copyElement, function(status:string,text:string){
          window.addToastMsg(status, text);
      })
      copyElement.classList.add('i-cm-completed');
    }
  })
}


// Layout
import {header} from "./layout/header";
// import {footer} from "./layout/footer";

import {banner} from "./layout/banner";
// // import {breadcrumbs} from "./layout/breadcrumbs";
import {popup} from "./layout/popup";

// Page
import {index} from "./pages/index";
import {about} from "./pages/about";
// import {products} from "./pages/products";
// import {news} from "./pages/news";
import {contact} from "./pages/contact";
import {tracktrace} from "./pages/track-trace";
// import {solution} from "./pages/solution";


// setInterval(() => {
//   window.dispatchEvent(
//     new CustomEvent('_tagselect', {
//       detail: `Init tag select 2`
//     })
//   )

//   window.dispatchEvent(
//     new CustomEvent('_imgClass', {
//       detail: `Init tag svg to img`
//     })
//   )
//   window.dispatchEvent(
//     new CustomEvent('_dateTime', {
//       detail: `Init datetimepicker`
//     })
//   )
//   window.dispatchEvent(
//     new CustomEvent('_initForm', {
//       detail: `Init Form event`
//     })
//   )
// }, 2000)


//////////////////////////////////////////////////////////////
window.addEventListener('DOMContentLoaded', function() {  
  // components
  

  // layout
  header();
  // footer();
  banner();
  
  // breadcrumbs();
  popup();
  
  // ----- Page in here ----- //
  index();
  about();
  // products();
  // news();
  contact();
  tracktrace();
  // solution();
  
  _imgClass();
  copyMaker();
  fancyboxLib();
  
  api.fetchData();

  settings();
})


import {gsapScroll} from './libraries/gsapScroll';
declare const YT: any;


window.addEventListener('load', function(){
  // lib
  // let promise = new Promise(function(res, rej){
  //   gsapScroll();
  //   res(true);
  // })

  // promise.then(function(value){
  //   document.body.style.overflow = "";
  //   load.remove();
  // })
  // .catch(function(err){
  //   console.log(err)
    
  // })
})




/////////////////////WEB FULLPAGE///////////////////////////
// import loadingAni from "./initAnimation/loading";

// const fullpageLoad = function(){
//   let loading = document.querySelector('#loading-container');
//   loadingAni.aniIn();
//   // import animateFullpage from "./components/animateFullpage";
//   // animateFullpage.default();
  
//   window.addEventListener('load', function() {
//     // swiper_nav();
//     allJs();
//     setTimeout(function(){
//       loading.classList.add('hiddenLoading');
//       setTimeout(function(){
//         loading.parentElement.removeChild(loading);

//         // let utiSubMap = document.getElementById('UtilitiesSubPage');
//         // if (utiSubMap){
//         //   let map = utiSubMap.querySelector('.map');
//         //   map.classList.add('animateActive');
//         // }
//       }, 700)
//       common();
//     }, loadingAni.delay)
//   })
// }

// fullpageLoad();

/////////////////////WEB NORMAL///////////////////////////

