declare const window: any;
declare const Swiper: any;
declare const $: any;
declare const gsap: any;
declare const ScrollTrigger: any;
declare const THREE: any;

export const contact = function () {
    let elem = document.getElementById('contactPage');
    if (elem){
        handleContactInfor(elem);
    }
};
function initSw(elem:HTMLElement){
    let sw = new Swiper(elem.querySelector('.contact-infor .swiper-infor'), {
        slidesPerView: 1,
        spaceBetween: 32,
        slidesPerGroup: 1,
        speed: 1200,
        autoHeight: true,
        // autoplay:{
        //     delay: 3000,
        //     disableOnInteraction: false,
        //     stopOnLastSlide: true,
        // },
        // allowTouchMove: false,

        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        // breakpoints: {
        //     0: {
        //         slidesPerView: 1,
        //         spaceBetween: 0,
        //     },
        //     480: {
        //         slidesPerView: 2,
        //         spaceBetween: 20,
        //     },
        //     768: {
        //         slidesPerView: 3,
        //         spaceBetween: 20,
        //     },
        //     1024: {
        //         slidesPerView: 4,
        //         spaceBetween: 32,
        //     }
        // }
    })
    return sw;
}

function initSwThumbInfor(elem:HTMLElement){
    let thumbHandle = document.querySelector('.contact-infor-thumbnails');
    if (thumbHandle){
        thumbHandle.addEventListener('click', function(e){
            e.stopPropagation();
            console.log('thum')
        })
    }
    let sw = new Swiper(elem.querySelector('.contact-infor-thumbnails .swiper'), {
        slidesPerView: 2,
    })
    return sw;
}

function handleContactInfor(elem:HTMLElement){
    const swSlider = initSw(elem);
    const thumbSlider = initSwThumbInfor(elem);

    // window.sliderThumbs(swSlider, thumbSlider, function(){
    //     console.log(thumbSlider.activeIndex);
    // });

    // const opts = elem.querySelectorAll('.contact-infor-thumbnails .contact-infor-item');
    // opts.forEach(function(opt, idx){
    //     opt.addEventListener('click', function(e){
    //         e.preventDefault();
    //         thumbSlider.slideTo(idx);
    //     })
    // })

    const lsThumb = document.querySelectorAll('.contact-infor-thumbnails .swiper-slide');
    window.delHandle(lsThumb, 'is-actived');
    lsThumb[0].classList.add('is-actived');

    swSlider.on('slideChange', function () {
        let activeIdx = swSlider.activeIndex;
        thumbSlider.slideTo(activeIdx);
        
        window.delHandle(lsThumb, 'is-actived');
        lsThumb[activeIdx].classList.add('is-actived');
    });

    thumbSlider.on('slideChange', function(){
        let activeIdx = thumbSlider.activeIndex;
        swSlider.slideTo(activeIdx);

        window.delHandle(lsThumb, 'is-actived');
        lsThumb[activeIdx].classList.add('is-actived');
    })

    lsThumb.forEach(function(itemThumb, idx){
        itemThumb.addEventListener('click', function(){
            swSlider.slideTo(idx);
        })
    })
}