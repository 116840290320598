function clickHandle(){
    let sts = document.querySelectorAll('.settings-item');
    sts.forEach(function(st){
        let icons = <HTMLElement>st.querySelector('.fixed-icons');
        // let list = st.querySelector('.fixed-list');

        icons.onclick = function(){
            if (icons.classList.contains('clicked')){
                icons.classList.remove('clicked');
                return;
            }

            sts.forEach(function(delSt){
                let delClass = <HTMLElement>delSt.querySelector('.fixed-icons');
                delClass.classList.remove('clicked');
            })
            icons.classList.add('clicked');
        }
    })
}


function scrollToTop(){
    let stt = <HTMLElement>document.querySelector('.scroll-to-top');
    if (stt){
        stt.onclick = function(){
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            })
        }

        let footer = <HTMLElement> document.querySelector('.footer');
        if (!footer){
            return;
        }

        let offsetFooter = footer.offsetTop;
        stt.style.display = "none";
        window.addEventListener('scroll', function(e){
            if (window.scrollY + window.innerHeight >= offsetFooter){
                // console.log(window.scrollY + window.innerHeight);
                stt.style.display = "";
            }
            else{
                stt.style.display = "none";
            }
        })
    }
}

export const settings = function(){
    clickHandle();

    scrollToTop();
}