declare const window: any;
declare const Swiper: any;
declare const $: any;
declare const gsap: any;
declare const ScrollTrigger: any;
declare const THREE: any;

export const tracktrace = function () {
    let elem = document.getElementById('trackTracePage');
    if (elem){
        handleResults(elem);
        handleOptionWrapper(elem);
    }
};

const handleResults = function(element:HTMLElement){
    let results = element.querySelectorAll('.result-item');
    results.forEach(function(resultItem:HTMLElement){
        let name = resultItem.querySelector('.result-item-name');
        // let content = resultItem.querySelector('.result-item-content');

        name.addEventListener('click', function(e){
            e.preventDefault();
            name.classList.toggle('is-actived');
        })
    })
}

const handleOptionWrapper = function(element:HTMLElement){
    let items = element.querySelectorAll('.tracktrace-searches-wrapper .wrapper-select .item');
    let wrapperOpts = element.querySelectorAll('.tracktrace-searches-wrapper .wrapper-option .option-item');

    if (items.length !== 0 && wrapperOpts.length !== 0 && items.length !== wrapperOpts.length){
        console.error('Option and select not found!');
        return;
    }

    items.forEach(function(item:HTMLElement, idxItem){
        item.addEventListener('click', function(e){
            e.preventDefault();

            window.delHandle(items, 'is-actived');
            window.delHandle(wrapperOpts, 'is-actived');

            item.classList.add('is-actived');
            wrapperOpts[idxItem].classList.add('is-actived');
        })
    })
}