declare const Fancybox:any;

function init(){  
  let ls = document.querySelectorAll('a[data-fancybox]');
  let arrayFancy:any[] = [];
  ls.forEach(function(item){
    if (!arrayFancy.includes(item.getAttribute('data-fancybox'))){
      arrayFancy.push(item.getAttribute('data-fancybox'));
    }
  })

  arrayFancy.forEach(function(item){
    Fancybox.bind(`[data-fancybox="${item}"]`, {
        Thumbs: {
          Carousel: {
            fill: false,
            center: true,
          },
        },
  
        Image: {
          zoom: true,
        },

        youtube: {
          controls: 1,
          showinfo: 0
        }
    });
  })
}



export const fancyboxLib = function(){
    init();
}