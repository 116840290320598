// import axios from "axios";
declare const $: any;
const viewHeight = window.innerHeight;

// Common function
const fetchData = () => {
	// const formData = new FormData();
	// formData.append("FirstName", "Sơn");
	// formData.append("LastName", "Vũ");
	// axios.post("/user", formData).then(function (response) {
	// 	console.log(response);
	// });

	// axios.get("./api/test.json")
	// 	.then((response) => {
	// 		return response;
	// 	})
	// 	.finally(() => {});

	if (document.getElementById('newsPage')){
		const handleLoadmoreNews = function(){
			const wrapper = <HTMLElement> document.querySelector('.news-list-wrapper');
			const loadmore = wrapper?.querySelector('.loadmore');
	
			if (loadmore){
				let checkingApi = false;
				// function apiLoadmore(){
				// 	const top = loadmore ? loadmore.getBoundingClientRect().top : 0; 
				// 	const rectTop = top + 60;
	
				// 	if (rectTop <= viewHeight){
				// 		if(checkingApi === false){
				// 			// call API
				// 			const data = {
				// 				id: loadmore.getAttribute('data-id') ? loadmore.getAttribute('data-id') : "",
				// 				page: loadmore.getAttribute('data-page') ? loadmore.getAttribute('data-page') : ""
				// 			}
		
				// 			$.ajax({
				// 				url: '/api/newsloadmore',
				// 				type: 'GET',
				// 				dataType: 'html',
				// 				data: data,
				// 				success: function(response:any) {
				// 					loadmore.remove();

				// 					const ul = wrapper.querySelector('ul');
				// 					ul.innerHTML += response;
									
				// 					handleLoadmoreNews();
				// 				},
				// 				error: function(xhr:any, status:any, error:any) {
				// 					console.log(error);
				// 					checkingApi = false;
				// 				}
				// 			});
				// 		}

				// 		checkingApi = true;
				// 		window.removeEventListener('scroll', apiLoadmore);
				// 	}
				// }
				// apiLoadmore();
				// window.addEventListener('scroll', apiLoadmore);


				function handleClickNewsAPI(e:any){
					e.preventDefault();
					if(checkingApi === false){
						// call API
						const data = {
							id: loadmore.getAttribute('data-id') ? loadmore.getAttribute('data-id') : "",
							page: loadmore.getAttribute('data-page') ? loadmore.getAttribute('data-page') : ""
						}
				
						$.ajax({
							url: '/api/newsloadmore',
							type: 'GET',
							dataType: 'html',
							data: data,
							success: function(response:any) {
								// remove event
								loadmore.classList.remove('loading');
								loadmore.removeEventListener('click', handleClickNewsAPI);

								loadmore.remove();

								// add data
								const ul = wrapper.querySelector('ul');
								ul.innerHTML += response;

				
								// repeat call api 
								handleLoadmoreNews();
							},
							error: function(xhr:any, status:any, error:any) {
								console.log(error);
								checkingApi = false;
								loadmore.classList.remove('loading');
							}
						});
						// loadmore.remove();
				
						checkingApi = true;
						loadmore.classList.add('loading');
					}
				}
				loadmore.addEventListener('click', handleClickNewsAPI)
			}
		}

		handleLoadmoreNews();
	}
};



export default {
	fetchData: fetchData,
}