declare const window: any;
declare const Swiper: any;
declare const $: any;
declare const gsap: any;
declare const ScrollTrigger: any;
declare const THREE: any;

export const about = function () {
    let elem = document.getElementById('aboutPage');
    if (elem){
        handleSwCertificates(elem);
        handleOptions(elem);
    }
};


function initSwCertificates(elem:HTMLElement){
    let sw = new Swiper(elem.querySelector('.swiper'), {
        slidesPerView: 4,
        spaceBetween: 32,
        slidesPerGroup: 1,
        speed: 800,
        autoplay:{
            delay: 3000,
            disableOnInteraction: false,
            stopOnLastSlide: true,
        },

        pagination: {
            el: elem.querySelector(".swiper-pagination"),
            clickable: true,
        },

        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 0,
            },
            480: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 32,
            }
        }
    })
    return sw;
}

function handleSwCertificates(elem:HTMLElement){
    const cers = elem.querySelectorAll('.about-certificates-wrapper');
    cers.forEach(function(cer:HTMLElement){
        initSwCertificates(cer);
    })
}


function handleOptions(elem:HTMLElement){
    let items = elem.querySelectorAll('.about-mv-options li');
    let wrappers = elem.querySelectorAll('.about-mv-wrapper');

    if (!items || !wrappers || items.length !== wrappers.length){
        console.error("Option not found!")
        return;
    }

    let checked = false;
    items.forEach(function(temp:HTMLElement, idxTemp){
        if (temp.classList.contains('is-actived')){
            wrappers[idxTemp].classList.add('is-actived');
            checked = true;
            return;
        }
    })

    if (checked == false){
        items[0].classList.add('is-actived');
        wrappers[0].classList.add('is-actived');
    }

    items.forEach(function(item:HTMLElement, idx){
        let btn = item.querySelector('.btn-option');
        btn.addEventListener('click', function(e){
            e.preventDefault();

            // remove is-actived
            window.delHandle(items, 'is-actived');
            window.delHandle(wrappers, 'is-actived');

            item.classList.add('is-actived');
            wrappers[idx].classList.add('is-actived');
        })
    })
}