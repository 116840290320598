declare const $: any;

export const _imgClass = function(){
    $("img.svg").each((function () {
        var t = $(this),
            e = t.attr("id"),
            n = t.attr("class"),
            a = t.attr("src");
        $.get(a, (function (a:any) {
            var i = $(a).find("svg");
            void 0 !== e && (i = i.attr("id", e)),
                void 0 !== n && (i = i.attr("class", n + " replaced-svg")),
                !(i = i.removeAttr("xmlns:a")).attr("viewBox") && i.attr("height") && i.attr("width") && i.attr("viewBox", "0 0 " + i.attr("height") + " " + i.attr("width")), t.replaceWith(i)
        }), "xml")
    }))
}
